<template>
   <div>
      <v-data-table :headers="columns" :items="filteredRecords" dense
         :items-per-page="15" :footer-props="{'items-per-page-options': [15, 30, 60]}" :hide-default-footer="!records.length"
         >
         <template v-slot:top>
            <v-toolbar flat>
               <v-text-field v-model="table.search" prepend-inner-icon="mdi-magnify" placeholder="Search" hide-details
                  class="mr-3 hidden-sm-and-down" style="max-width: 240px;" flat single-line clearable
                  />
               <v-spacer />
               <v-btn x-large icon tile color="secondary" class="mx-1" @click="option.id = 0">
                  <v-icon size="48">
                     mdi-plus-box
                  </v-icon>
               </v-btn>
            </v-toolbar>
            <v-divider />
         </template>
         <template v-slot:[`item.zone`]="{ item }">
            <div class="text-truncate">
               {{ zoneText(item.zone) }}
            </div>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'RU')" class="ml-2" @click="modifyRecord(item)">
               {{ $hasRole(item.kind.toLowerCase(), 'U') ? 'mdi-pencil' : 'mdi-eye' }}
            </v-icon>
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'D')" class="ml-2" @click="removeRecord(item)">
               mdi-delete
            </v-icon>
         </template>
         <template v-slot:no-data>
            <div class="my-5" style="font-size: 1.15rem;">
               No data available
            </div>
         </template>
      </v-data-table>
      <v-dialog v-model="dialog.del" max-width="330px">
         <v-card>
            <v-list-item two-line class="grey lighten-2 px-6 py-2">
               <v-list-item-content dark>
                  <v-list-item-title>{{ `Delete item ${record.name}?` }}</v-list-item-title>
                  <v-list-item-subtitle>{{ record.identity }}</v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
            <v-card-actions class="px-6 py-4">
               <v-spacer />
               <v-btn color="grey darken-2" text class="px-4" @click="dialog.del = false">
                  Cancel
               </v-btn>
               <v-btn color="secondary" depressed class="px-4" @click="deleteRecord">
                  OK
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
export default {
   name: 'NRoleTable',

   props: {
      option: { type: Object },
      records: { type: Array },
      zones: { type: Array },
   },

   data: () => ({
      isUsed: false,
      columns: [
         { text: 'Template Name', value: 'name' },
         { text: 'Code', value: 'code', width: 115 },
         { text: 'Management', value: 'zone' },
         { text: 'Actions', value: 'actions', width: 80, align: 'end pl-0', sortable: false },
      ],
      dialog: { del: false },
      table: { search: '', filter: [] },
      record: {},
   }),

   computed: {
      filteredRecords () {
         return this.records.filter((j) => {
            const search = this.table.search?.toLowerCase()
            var filteredName = j.name && j.name.toLowerCase().indexOf(search) !== -1
            return !search || filteredName
         })
      },
      zoneText () {
         return zone => (this.zones.find(j => j.zone === zone) || { name: zone === '*' ? 'All Management' : zone }).name
      },
   },
   created () {
      this.$store.state.user.code !== 'SPA' && this.columns.splice(this.columns.findIndex(j => j.value === 'zone'), 1)
   },
   methods: {
      modifyRecord (item) {
         Object.assign(this.option, item)
         this.$vuetify.goTo(0)
      },
      removeRecord (item) {
         this.record = Object.assign({}, { id: -1 }, item)
         this.dialog.del = true
      },
      deleteRecord () {
         this.$emit('delete', this.record)
         this.dialog.del = false
      },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header > tr > th span {
   font-size: 1.0rem;
   letter-spacing: 0;
   min-height: 60px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 0.9rem;
}
::v-deep .v-data-footer,
::v-deep .v-data-footer__select .v-select__selections .v-select__selection--comma {
   font-size: 0.9rem;
}
</style>
